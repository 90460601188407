import { Link } from 'gatsby';
import React from 'react';
import Layout from '../components/layout/layout';

const Uslugi = () => {
    return (
        <Layout
            title="Usługi - WrapTown - profesjonalne oklejanie samochodów"
            desc="Firma WrapTown oferuje usługi profesjonalnego oklejania samochodów. Zapraszamy jeśli chcesz zmienić kolor swojego auta lub ochronić lakier folią bezbarwną."
            url="https://wraptown.pl/uslugi"
            type="website"
        >
            <main className="px-4 max-w-7xl mx-auto pt-20 pb-20 lg:px-6">
                <div className="mb-10">
                    <p>
                        <Link to="/">Home</Link> / Usługi
                    </p>
                </div>
                <div className="mb-10">
                    <h1 className="text-4xl  mb-6 font-extrabold text-black xl:text-5xl leading-tight xl:leading-snug">
                        Usługi - WrapTown
                    </h1>
                    <p>
                        Wykonujemy usługi takie jak zmiana koloru auta,
                        dechroming czy ochrona lakieru.
                    </p>
                </div>
                <div className="grid grid-flow-row gap-4 sm:grid-cols-2 lg:grid-cols-3 lg:gap-8">
                    <Link to="/uslugi/zmiana-koloru-auta">
                        <div className="relative">
                            <div className="absolute bottom-4 left-4 h-8 px-3 md:h-10 mr-4 md:px-5 text-white flex justify-center items-center bg-blue-700 transition-colors duration-150 border border-blue-700 rounded-lg focus:shadow-outline">
                                <h2 className="font-bold">
                                    Zmiana koloru auta
                                </h2>
                            </div>
                            <img
                                src="../../site/color.webp"
                                alt="Zmiana koloru auta - WrapTown - Profesjonalne oklejanie aut"
                            />
                        </div>
                    </Link>
                    <Link to="/uslugi/dechroming">
                        <div className="relative">
                            <div className="absolute bottom-4 left-4 h-8 px-3 md:h-10 mr-4 md:px-5 text-white flex justify-center items-center bg-blue-700 transition-colors duration-150 border border-blue-700 rounded-lg focus:shadow-outline">
                                <h2 className="font-bold">Dechroming</h2>
                            </div>
                            <img
                                src="../../site/dechroming.webp"
                                alt="Dechroming - WrapTown - Profesjonalne oklejanie aut"
                            />
                        </div>
                    </Link>
                    <Link to="/uslugi/ochrona-lakieru">
                        <div className="relative w-auto h-auto">
                            <div className="absolute bottom-4 left-4 h-8 px-3 md:h-10 mr-4 md:px-5 text-white flex justify-center items-center bg-blue-700 transition-colors duration-150 border border-blue-700 rounded-lg focus:shadow-outline">
                                <h2 className="font-bold">Ochrona lakieru</h2>
                            </div>
                            <img
                                src="../../site/ppf.webp"
                                alt="Ochrona lakieru - WrapTown - Profesjonalne oklejanie aut"
                            />
                        </div>
                    </Link>
                </div>
            </main>
        </Layout>
    );
};

export default Uslugi;
